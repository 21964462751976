import { Box, ChakraProps, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FC } from 'react'
import { useClientSetting } from 'src/clientSetting/Provider'
import { clientSettings } from 'src/clientSetting/settings'
import { useConnectParams } from 'src/connect/useParams'
import { pagesPath } from 'src/routes/$path'

export const Footer: FC<{} & ChakraProps> = ({ ...props }) => {
  const { getUrlQuery } = useConnectParams()
  const clientId = useClientSetting() || clientSettings.default

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="50px"
        px={6}
        backgroundColor="gray.50"
        {...props}
      >
        <Box color="gray.600">© MICIN,Inc.</Box>
        {clientId === clientSettings.momo || clientId === clientSettings['curon-clinic-web'] ? (
          <Box color="gray.600">
            <NextLink href={pagesPath.contact.$url(getUrlQuery())}>
              <Link>お問い合わせ</Link>
            </NextLink>
          </Box>
        ) : null}
      </Box>
    </>
  )
}
