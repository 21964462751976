import type { Query as Query_1roems4 } from '../pages/account-settings/index.page'
import type { Query as Query_gym8u1 } from '../pages/change-default-password/index.page'
import type { Query as Query_15d9hxv } from '../pages/contact/index.page'
import type { Query as Query_fqac4i } from '../pages/index.page'
import type { Query as Query_1lpl5mg } from '../pages/mfa/invalidation/index.page'
import type { Query as Query_1wdyyt } from '../pages/password/edit/index.page'
import type { Query as Query_1tlblqr } from '../pages/profile/edit/index.page'
import type { Query as Query_1ct7r8i } from '../pages/reauthentication/index.page'
import type { Query as Query_5vuduz } from '../pages/reauthentication/mfa/sms/index.page'
import type { Query as Query_13m6ngh } from '../pages/recovery/email/index.page'
import type { Query as Query_q2jtqw } from '../pages/recovery/password/index.page'
import type { Query as Query_1qi8uk } from '../pages/recovery/pin/index.page'
import type { Query as Query_82z40k } from '../pages/signin/mfa/sms-recovery-required/index.page'
import type { Query as Query_18hqd3i } from '../pages/signin/mfa/sms/index.page'
import type { Query as Query_eam2li } from '../pages/signout/index.page'
import type { Query as Query_jm6rno } from '../pages/sms/recovery/complete/index.page'
import type { Query as Query_fq9t9a } from '../pages/sms/recovery/index.page'
import type { Query as Query_3uf79j } from '../pages/sms/recovery/[uid]/pin/index.page'
import type { Query as Query_4pso4 } from '../pages/sms/registration/index.page'
import type { Query as Query_1a4hzj1 } from '../pages/sms/registration/[uid]/pin/index.page'
import type { Query as Query_rh6pwe } from '../pages/version.page'

export const pagesPath = {
  $404: {
    $url: (url?: { hash?: string | undefined } | undefined) => ({
      pathname: '/404' as const,
      hash: url?.hash,
    }),
  },
  account_settings: {
    $url: (url: { query: Query_1roems4; hash?: string | undefined }) => ({
      pathname: '/account-settings' as const,
      query: url.query,
      hash: url.hash,
    }),
  },
  change_default_password: {
    $url: (url: { query: Query_gym8u1; hash?: string | undefined }) => ({
      pathname: '/change-default-password' as const,
      query: url.query,
      hash: url.hash,
    }),
  },
  contact: {
    $url: (url: { query: Query_15d9hxv; hash?: string | undefined }) => ({
      pathname: '/contact' as const,
      query: url.query,
      hash: url.hash,
    }),
  },
  help: {
    mfa: {
      $url: (url?: { hash?: string | undefined } | undefined) => ({
        pathname: '/help/mfa' as const,
        hash: url?.hash,
      }),
    },
  },
  mfa: {
    invalidation: {
      $url: (url: { query: Query_1lpl5mg; hash?: string | undefined }) => ({
        pathname: '/mfa/invalidation' as const,
        query: url.query,
        hash: url.hash,
      }),
    },
  },
  password: {
    edit: {
      $url: (url: { query: Query_1wdyyt; hash?: string | undefined }) => ({
        pathname: '/password/edit' as const,
        query: url.query,
        hash: url.hash,
      }),
    },
  },
  profile: {
    edit: {
      $url: (url: { query: Query_1tlblqr; hash?: string | undefined }) => ({
        pathname: '/profile/edit' as const,
        query: url.query,
        hash: url.hash,
      }),
    },
  },
  reauthentication: {
    mfa: {
      sms: {
        $url: (url: { query: Query_5vuduz; hash?: string | undefined }) => ({
          pathname: '/reauthentication/mfa/sms' as const,
          query: url.query,
          hash: url.hash,
        }),
      },
    },
    $url: (url: { query: Query_1ct7r8i; hash?: string | undefined }) => ({
      pathname: '/reauthentication' as const,
      query: url.query,
      hash: url.hash,
    }),
  },
  recovery: {
    email: {
      $url: (url: { query: Query_13m6ngh; hash?: string | undefined }) => ({
        pathname: '/recovery/email' as const,
        query: url.query,
        hash: url.hash,
      }),
    },
    password: {
      $url: (url: { query: Query_q2jtqw; hash?: string | undefined }) => ({
        pathname: '/recovery/password' as const,
        query: url.query,
        hash: url.hash,
      }),
    },
    pin: {
      $url: (url: { query: Query_1qi8uk; hash?: string | undefined }) => ({
        pathname: '/recovery/pin' as const,
        query: url.query,
        hash: url.hash,
      }),
    },
  },
  signin: {
    mfa: {
      sms: {
        $url: (url: { query: Query_18hqd3i; hash?: string | undefined }) => ({
          pathname: '/signin/mfa/sms' as const,
          query: url.query,
          hash: url.hash,
        }),
      },
      sms_recovery_required: {
        $url: (url: { query: Query_82z40k; hash?: string | undefined }) => ({
          pathname: '/signin/mfa/sms-recovery-required' as const,
          query: url.query,
          hash: url.hash,
        }),
      },
    },
  },
  signout: {
    $url: (url: { query: Query_eam2li; hash?: string | undefined }) => ({
      pathname: '/signout' as const,
      query: url.query,
      hash: url.hash,
    }),
  },
  sms: {
    recovery: {
      _uid: (uid: string | number) => ({
        pin: {
          $url: (url: { query: Query_3uf79j; hash?: string | undefined }) => ({
            pathname: '/sms/recovery/[uid]/pin' as const,
            query: { uid, ...url.query },
            hash: url.hash,
          }),
        },
      }),
      complete: {
        $url: (url: { query: Query_jm6rno; hash?: string | undefined }) => ({
          pathname: '/sms/recovery/complete' as const,
          query: url.query,
          hash: url.hash,
        }),
      },
      $url: (url: { query: Query_fq9t9a; hash?: string | undefined }) => ({
        pathname: '/sms/recovery' as const,
        query: url.query,
        hash: url.hash,
      }),
    },
    registration: {
      _uid: (uid: string | number) => ({
        pin: {
          $url: (url: { query: Query_1a4hzj1; hash?: string | undefined }) => ({
            pathname: '/sms/registration/[uid]/pin' as const,
            query: { uid, ...url.query },
            hash: url.hash,
          }),
        },
      }),
      $url: (url: { query: Query_4pso4; hash?: string | undefined }) => ({
        pathname: '/sms/registration' as const,
        query: url.query,
        hash: url.hash,
      }),
    },
  },
  version: {
    $url: (url: { query: Query_rh6pwe; hash?: string | undefined }) => ({
      pathname: '/version' as const,
      query: url.query,
      hash: url.hash,
    }),
  },
  $url: (url: { query: Query_fqac4i; hash?: string | undefined }) => ({
    pathname: '/' as const,
    query: url.query,
    hash: url.hash,
  }),
}

export type PagesPath = typeof pagesPath
