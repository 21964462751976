import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  VStack,
  HStack,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useConnectParams } from 'src/connect/useParams'
import { reactHookFormDefaultOptions } from 'src/form/reactHookFormDefaultOptions'
import {
  compose,
  validateEmailPattern,
  validateMaxLength,
  validateRequired,
} from 'src/form/validations'
import { PagePadding } from 'src/layout/PagePadding'
import { PageRoot } from 'src/layout/PageRoot'
import { Footer } from 'src/navigation/Footer'
import { pagesPath } from 'src/routes/$path'
import { CLINIC_TERMS, EXTERNAL_TRANSMISSION, PRIVACY_POLICY } from 'src/urls'
import MomoTopArt from './momo_top_art.svg'
import { WarnFillIcon } from '@micin-jp/icons'

type FormValues = {
  email: string
  password: string
}

export const IndexMomo: FC<{
  onSubmitEmailLogin: (values: FormValues) => void
  submitting: boolean
}> = ({ onSubmitEmailLogin, submitting, ...props }) => {
  const { getUrlQuery } = useConnectParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    ...reactHookFormDefaultOptions,
  })

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

  return (
    <PageRoot>
      {process.env.AUTH_PF_ENV === 'stg7' && (
        <HStack
          border="1px solid"
          borderColor="#FCD79C"
          bg="#FEF6E6"
          p="16px 24px"
          justifyContent="center"
          alignItems="center"
        >
          <WarnFillIcon color="#E98E16" />

          <Text fontSize="16px" fontWeight={700}>
            この画面はデモ環境です。動作確認にご利用いただけます。
          </Text>
        </HStack>
      )}
      <Box bgColor="green.50">
        <Container mt={12} mb={6}>
          <Center>
            <VStack spacing={6}>
              <MomoTopArt />

              <Box fontWeight={600} fontSize="xl" color="logoGreen">
                医療機関向け
              </Box>
            </VStack>
          </Center>
        </Container>
      </Box>
      <Container maxW="centeredRow">
        <form onSubmit={handleSubmit(v => onSubmitEmailLogin(v))}>
          <Box mt="64px">
            <FormControl id="email" isInvalid={!!errors.email}>
              <FormLabel fontWeight="normal" color="gray.800">
                メールアドレス
              </FormLabel>
              <Input
                type="text"
                size="lg"
                inputMode="email"
                placeholder="メールアドレスを入力"
                {...register('email', {
                  ...compose(
                    validateRequired('メールアドレス'),
                    validateEmailPattern('メールアドレス'),
                    validateMaxLength('メールアドレス', 76),
                  ),
                })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="password" mt={6} isInvalid={!!errors.password}>
              <FormLabel fontWeight="normal" color="gray.800">
                パスワード
              </FormLabel>
              <Input
                type={passwordVisible ? 'text' : 'password'}
                size="lg"
                placeholder="パスワードを入力"
                {...register('password', {
                  ...compose(validateRequired('パスワード')),
                })}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <Box mt={4}>
              <Checkbox
                checked={passwordVisible}
                onChange={e => setPasswordVisible(e.target.checked)}
              >
                パスワードを表示する
              </Checkbox>
            </Box>
          </Box>
          <Box mt={8}>
            <Text>
              <Link href={CLINIC_TERMS} isExternal color="blue.400" mx={1}>
                利用規約
              </Link>
              および
              <Link href={PRIVACY_POLICY} isExternal color="blue.400" mx={1}>
                プライバシーポリシー
              </Link>
              ・
              <Link href={EXTERNAL_TRANSMISSION} isExternal color="blue.400" mx={1}>
                外部送信ポリシー
              </Link>
              に同意される方のみ、ログインください。
            </Text>
          </Box>
          <Box mt={6}>
            <Button width="100%" size="lg" fontWeight="bold" type="submit" isLoading={submitting}>
              ログインする
            </Button>
          </Box>
          <Box mt={6} textAlign="center">
            <NextLink href={pagesPath.recovery.email.$url(getUrlQuery())}>
              <Link fontWeight="bold" color="gray.600">
                パスワードがわからない方はこちら
              </Link>
            </NextLink>
          </Box>
        </form>
      </Container>
      <PagePadding />
      <Footer />
    </PageRoot>
  )
}
