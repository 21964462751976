import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useConnectParams } from 'src/connect/useParams'
import { reactHookFormDefaultOptions } from 'src/form/reactHookFormDefaultOptions'
import {
  compose,
  validateEmailPattern,
  validateMaxLength,
  validateRequired,
} from 'src/form/validations'
import { PagePadding } from 'src/layout/PagePadding'
import { PageRoot } from 'src/layout/PageRoot'
import { Footer } from 'src/navigation/Footer'
import { JapanOnlyNote } from 'src/navigation/Header/JapanOnlyNote'
import { pagesPath } from 'src/routes/$path'
import { CLINIC_TERMS, EXTERNAL_TRANSMISSION, PRIVACY_POLICY } from 'src/urls'
import CommonTopArt from './common_top_art.svg'

type FormValues = {
  email: string
  password: string
}

export const IndexCommon: FC<{
  onSubmitEmailLogin: (values: FormValues) => void
  submitting: boolean
}> = ({ onSubmitEmailLogin, submitting, ...props }) => {
  const { getUrlQuery } = useConnectParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    ...reactHookFormDefaultOptions,
  })

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

  return (
    <PageRoot bg="#EEF2F6">
      <JapanOnlyNote
        backgroundColor="#EEF2F6"
        borderRadius="1px"
        borderBottom="1px solid"
        borderBottomColor="#C2CCD6"
        textColor="gray.900"
      />
      <Container maxWidth="100%">
        <Center>
          <Box
            bg="#FFFFFF"
            px="80px"
            py="64px"
            mx="auto"
            mt={12}
            height="auto"
            gap="24px"
            width="560px"
            sx={{ boxShadow: '0px 8px 20px 0px #00000014' }}
          >
            <Box>
              <Box mb={6}>
                <Center>
                  <Stack spacing={6} align="center">
                    <CommonTopArt />
                    <Text fontSize="2xl" fontWeight="600">
                      医療機関さま向けログイン
                    </Text>
                  </Stack>
                </Center>
              </Box>
              <Stack spacing={4} align="start">
                <Box>
                  <Text>
                    下記のサービスをご利用の方は、ご登録のメールアドレスとパスワードでログインしてください。
                  </Text>
                </Box>
                <Box bg="green.50" px={4} py={4} width="100%">
                  <List pl={2} spacing={2}>
                    <ListItem>・オンライン診療サービスcuron</ListItem>
                    <ListItem>・クロンスマートパス</ListItem>
                  </List>
                </Box>
              </Stack>
            </Box>
            <form onSubmit={handleSubmit(v => onSubmitEmailLogin(v))}>
              <Box mt="32px">
                <FormControl id="email" isInvalid={!!errors.email}>
                  <FormLabel fontWeight="600" color="gray.800">
                    メールアドレス
                  </FormLabel>
                  <Input
                    type="text"
                    size="lg"
                    inputMode="email"
                    placeholder="メールアドレスを入力"
                    {...register('email', {
                      ...compose(
                        validateRequired('メールアドレス'),
                        validateEmailPattern('メールアドレス'),
                        validateMaxLength('メールアドレス', 76),
                      ),
                    })}
                  />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
                <FormControl id="password" mt={6} isInvalid={!!errors.password}>
                  <FormLabel fontWeight="600" color="gray.800">
                    パスワード
                  </FormLabel>
                  <Input
                    type={passwordVisible ? 'text' : 'password'}
                    size="lg"
                    placeholder="パスワードを入力"
                    {...register('password', {
                      ...compose(validateRequired('パスワード')),
                    })}
                  />
                  <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                </FormControl>
                <Box mt={4}>
                  <Checkbox
                    checked={passwordVisible}
                    onChange={e => setPasswordVisible(e.target.checked)}
                  >
                    パスワードを表示する
                  </Checkbox>
                </Box>
              </Box>
              <Box mt={8}>
                <Text>
                  <Link
                    href={CLINIC_TERMS}
                    isExternal
                    color="green.600"
                    textDecoration="underline"
                    mx={1}
                  >
                    利用規約
                  </Link>
                  および
                  <Link
                    href={PRIVACY_POLICY}
                    isExternal
                    color="green.600"
                    textDecoration="underline"
                    mx={1}
                  >
                    プライバシーポリシー
                  </Link>
                  ・
                  <Link
                    href={EXTERNAL_TRANSMISSION}
                    isExternal
                    color="green.600"
                    textDecoration="underline"
                    mx={1}
                  >
                    外部送信ポリシー
                  </Link>
                  に同意される方のみ、ログインください。
                </Text>
              </Box>
              <Box mt={6}>
                <Button
                  width="100%"
                  size="lg"
                  fontWeight="bold"
                  type="submit"
                  isLoading={submitting}
                >
                  ログインする
                </Button>
              </Box>
              <Box mt={6} textAlign="center">
                <NextLink href={pagesPath.recovery.email.$url(getUrlQuery())}>
                  <Link color="green.600" textDecoration="underline">
                    パスワードがわからない方はこちら
                  </Link>
                </NextLink>
              </Box>
            </form>
          </Box>
        </Center>
      </Container>
      <Container mt={12}>
        <Center>
          <Link
            href="https://id.curon.co/whitepaper.pdf"
            color="green.600"
            textDecoration="underline"
            isExternal
          >
            セキュリティホワイトペーパー
          </Link>
        </Center>
      </Container>
      <PagePadding />
      <Footer bg="#EEF2F6" />
    </PageRoot>
  )
}
